import React, { useState } from 'react';
import axios from 'axios';

import Layout from '../components/Layout';
const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubitted] = useState(false);

  const onSubmit = async () => {
    const validEmail = email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (!validEmail) {
      alert('Please enter a valid email address, before submitting.');
      return;
    }
    setSubitted(true);
    await axios.post(`${API_URL}/signin`, { email });
    return;
  };

  return (
    <Layout fullMenu>
      <article id="main">
        <header style={{ height: 300 }}>
          <h2>Sign In</h2>
        </header>
        <section className="wrapper style5">
          <div className="inner">
            {submitted ? (
              <div>
                <h2>Check your inbox for a link to your account!</h2>
                <p>
                  If you do not see the email in your inbox, please check your
                  spam folder.
                </p>
              </div>
            ) : (
              <form>
                <label>
                  Enter your email to create an account or sign in
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
                <button type="button" onClick={onSubmit}>
                  Continue
                </button>
              </form>
            )}
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default SignIn;
